<template>
  <div class="py-7 px-8">
    <BookATableIframe />
  </div>
</template>

<script>
import BookATableIframe from "@/views/dashboard/components/advertising/BookATableIframe";
import { mapState } from "vuex";

export default {
  name: "BookingSettingsWidget",
  props: {},
  components: {
    BookATableIframe,
  },

  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", {
      fetched_business_hours: "business_hours",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
